<template>
  <div class="kyMsg">
    <!-- 板块标题组件 -->
    <part-title>
      <h3 class="part-title f-cc" slot="titleIcon">
        <span class="iconfont icon-zixun1"></span>
        考研资讯
      </h3>
    </part-title>
    <div class="msgContent f-cs">
      <div class="picTxt">
        <img src="https://yx.wendu.com/images/jgkx_pic.jpg" width="100%" alt="">
        <ul class="frist">
          <li v-for="item in msgSliceTwo" :key="item.id">
            <a class="f-cs link" href="javascript:void(0)">
              <p class="txt">{{item.title}}</p>
              <span>{{item.time}}</span>
            </a>
          </li>
        </ul>
      </div>
      <div class="titleTxt">
        <ul>
          <li class="f-cs" v-for="item in msgSliceMore" :key="item.id">
            <a class="f-cs link" href="javascript:void(0)">
              <p class="txt">{{item.title}}</p>
              <span>{{item.time}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Title from './Title.vue'
export default {
  components: {
    partTitle: Title
  },
  mounted () {
    // console.log(this.kyMsg)
  },
  computed: {
    ...mapState(['kyMsg']),
    msgSliceTwo () {
      const { kyMsg } = this
      const newMsg = kyMsg.slice(0, 2)
      return newMsg
    },
    msgSliceMore () {
      const { kyMsg } = this
      const moreMsg = kyMsg.slice(2, 9)
      return moreMsg
    }
  }
}
</script>

<style lang="less" scoped>
.kyMsg{
  width:820px;
  background:#fff;
}
.frist{
  margin-top:16px;
}
.msgContent{
  width:100%;
  padding:20px 20px 10px;
  align-items: initial;
  div{
    width:48%;
    overflow:hidden;
    li{
      font-size:14px;
      position:relative;
      padding-left:15px;
      line-height:26px;
      margin-bottom:10px;
      a{
        color:#53586c;
      }
      &:hover::before{
        background:#007ff4;
      }
      &::before{
        position:absolute;
        content:'';
        left:0;
        top:50%;
        transform:translateY(-50%);
        width:6px;
        height:6px;
        border-radius:3px;
        background:#ccced8;
        transition:all .3s linear;
      }
      a:hover span{
        color:#4d4d4d;
      }
      p{
        width:308px;
        padding-right:80px;
      }
      span{
        width:60px;
        font-size:13px;
      }
    }
  }
}
</style>
