import { nanoid } from 'nanoid'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const DATE = new Date()
const pzImg = 'https://yx.wendu.com/images/'
export default new Vuex.Store({
  state: {
    navBar: [
      { title: '首页', path: '/', id: nanoid() },
      { title: '考研周末班', path: 'https://yx.wendu.com/zt/caihong', id: nanoid() /* menu: [{ title: '面授课程', id: nanoid() }, { title: '考研网课', id: nanoid() }] */ },
      { title: '23集训营', path: 'https://yx.wendu.com/zt/qnyfjxy', id: nanoid() /* menu: [{ title: '院校专业', id: nanoid() }, { title: '招生简章', id: nanoid() }, { title: '考试大纲', id: nanoid() }, { title: '考研常识', id: nanoid() }] */ },
      { title: '复试集训营', path: 'https://yx.wendu.com/zt/fushijxy', id: nanoid() /* menu: [{ title: '考研动态', id: nanoid() }, { title: '考研公共课', id: nanoid() }, { title: '考研专业课', id: nanoid() }, { title: '联考综合', id: nanoid() }] */ },
      { title: 'MPAcc', path: 'https://yx.wendu.com/zt/mpacc', id: nanoid() /* menu: [{ title: '考研复试', id: nanoid() }, { title: '考研调剂', id: nanoid() }] */ },
      { title: '高端1v1', path: 'https://yx.wendu.com/zt/zyk1v1', id: nanoid() /* menu: [{ title: '英语', id: nanoid() }, { title: '数学', id: nanoid() }, { title: '政治', id: nanoid() }, { title: '专业课', id: nanoid() }] */ },
      { title: '在职考研', path: 'https://yx.wendu.com/zt/zzky', id: nanoid() /* menu: [{ title: '英语', id: nanoid() }, { title: '数学', id: nanoid() }, { title: '政治', id: nanoid() }, { title: '专业课', id: nanoid() }, { title: '专业硕士', id: nanoid() }] */ },
      { title: '成人学历', path: 'https://yx.wendu.com/xueli', id: nanoid() },
      { title: '关于我们', path: 'https://yx.wendu.com/zt/zhym', id: nanoid() }
    ],
    hotCourse: [
      { title: '23考研集训营', id: nanoid(), path: '/zt/qnyfjxy' },
      { title: '半年集训营', id: nanoid(), path: '/zt/bnyfjxy' },
      { title: 'MBA', id: nanoid(), path: '/zt/mba' },
      { title: '秋季集训营', id: nanoid(), path: '/zt/qiujijx' },
      { title: '考前集训营', id: nanoid(), path: '/zt/zhym' },
      { title: '彩虹卡课程', id: nanoid(), path: '/zt/caihong' },
      { title: '在职考研课程', id: nanoid(), path: '/zt/zzky' },
      { title: '复试集训课程', id: nanoid(), path: '/zt/fushijxy' },
      { title: 'MPAcc', id: nanoid(), path: '/zt/mpacc' }
    ],
    banner: [
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/202202271542.jpg', id: nanoid(), path: '' },
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/202112211734579692.png', id: nanoid(), path: '' },
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/202202191638.jpg', id: nanoid(), path: '' },
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/202112231741317407.png', id: nanoid(), path: '' },
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/20211227_171922_jpeg_61c9851a3984enxl_x4.png', id: nanoid(), path: '' },
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/20222191541.jpg', id: nanoid(), path: '' },
      { imgUrl: 'https://yx.wendu.com/jx.wendu.com/img/20211230061631241.png', id: nanoid(), path: '' }
    ],
    news: [
      {
        title: '热门推荐', id: nanoid(), icon: 'icon-rementuijian', list: [{ txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }]
      },
      {
        title: '考研资讯', id: nanoid(), icon: 'icon-zixun', list: [{ txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }]
      },
      {
        title: '备考资料', id: nanoid(), icon: 'icon-ziliao', list: [{ txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }]
      },
      {
        title: '十四大学科', id: nanoid(), icon: 'icon-xiangmu', list: [{ txt: '文学', id: nanoid() }, { txt: '教育学', id: nanoid() }, { txt: '历史学', id: nanoid() }]
      },
      {
        title: '文都资讯', id: nanoid(), icon: 'icon-xinwen', list: [{ txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }, { txt: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), date: `${DATE.getFullYear()}-${DATE.getMonth()}` }]
      },
      {
        title: '院校库', id: nanoid(), icon: 'icon-xinwen', list: [{ txt: '南昌大学', id: nanoid() }, { txt: '江西财经大学', id: nanoid() }, { txt: '江西师范大学', id: nanoid() }]
      }
    ],
    kyMsg: [
      { title: '2022考研报考点设置及报名公告（汇总）', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '2022考研网上正式报名时间:10月5日至10月25日!', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '2022研招统考正式报名正在进行!考生注意事项须知!', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '各省市招办2022考研网上确认时间汇总', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '上海市2022年研考网上确认报名点安排表!', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '考研资讯和热点总结(10月11日)', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '北京交通大学报考点2022年硕考网报信息有误名单', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '2022考研报名:报考类别错误直接导致报名失败!', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '2022考研现场确认,这几个省份根据实际情况自行安排!', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` },
      { title: '2022考研报名:这些省市非本地报考者需提交社保证明!', id: nanoid(), time: `${DATE.getFullYear()}-${DATE.getMonth() + 1}` }
    ],
    guide: ['金融学', '艺术学', '医学', '教育学', '计算机', '经济学', '心理学', '法硕', '新闻传播', 'MBA', 'MPACC', '哲学', '理学', '工学', '更多'],
    course: [
      { title: '彩虹卡', id: nanoid(), content: [{ title: '钻石彩虹卡', pic: `${pzImg}20201203031146966.png`, id: nanoid(), path: '/zt/caihong' }, { title: '钻飞跃彩虹卡', pic: `${pzImg}20201203031313216.png`, id: nanoid(), path: '/zt/caihong' }, { title: '魔力彩虹卡', pic: `${pzImg}20201203031354156.png`, id: nanoid(), path: '/zt/caihong' }, { title: '定向彩虹卡', pic: `${pzImg}20201203031438860.png`, id: nanoid(), path: '/zt/caihong' }] },
      { title: '集训营', id: nanoid(), content: [{ title: '全年集训营' }] }
    ],
    materials: [
      { title: '英语', id: 0, imgUrl: `${pzImg}bkzl_eng.png`, txt: '对于英语来说，在本阶段应该做到对指定参考书的重点内容已经完全掌握，从罗列的知识点中找出考点和重点加以巩固强化，做到不管从哪方面出题都能应付自如的程度。', content: ['2022考研精刷英语一阅读真题', '考研英语阅读高分笔记模板', '考研英语写作必考话题词集锦', '考研英语历年真题重点词组与词汇', '2022考研英语二大纲原文', '2022考研英语一大纲原文', '考研英语难懂的真题短语726个', '考研英语：2009至2018年英语一真题佳句应用'] },
      { title: '政治', id: 1, imgUrl: `${pzImg}bkzl_pol.png`, txt: '考研政治的考查范围主要包括：马克思主义基本原理、毛泽东思想和中国特色社会主义理论体系概论、中国近现代史纲要、思想道德修养与法律基础、形势与政策以及当代世界经济与政治。', content: ['考研政治马原：共产党宣言', '考研政治思修帽子题总结', '考研政治史纲帽子题总结', '考研政治毛中特帽子题总结', '考研政治:党的重要会议汇总', '考研政治人物著作|口号|思想汇总', '考研政治各题型做题技巧', '考研政治强化考点学霸笔记'] },
      { title: '数学', id: 2, imgUrl: `${pzImg}bkzl_math.png`, txt: '考研数学解答题主要考查综合运用知识的能力、逻辑推理能力、空间想象能力以及分析、解决实际问题的能力', content: ['考研数学微分中值定理的基础理论和常见优秀题型解法', '考研数学考点真题大全', '考研数学积分表147个公式的推导', '考研数学：利用函数图像快速记忆麦克劳林展开', '考研数学极限：洛必达的失效', '考研数学极限：具有振荡间断点的函数与图像', '考研数学极限：具有振荡间断点的函数与图像', '考研数学：常见“钓鱼”不定积分整理'] },
      { title: '专业课', id: 3, imgUrl: `${pzImg}bkzl_profe.png`, txt: '对于报考本专业的考生来说，由于已经有了本科阶段的专业基础和知识储备，相对会比较容易进入状态。', content: ['2022考研心理学312考试大纲原文', '2022考研暑期备考手册—艺术类', '2022考研暑期备考手册—医学', '2022考研暑期备考手册—心理学', '2022考研暑期备考手册—法学和马克思', '2021年华东交通大学路基路面工程考研复试资料', '2021考研临床医学综合能力（西医）真题及答案（完整版）', '考研经济学专业百科知识'] },
      { title: '常识', id: 4, imgUrl: `${pzImg}bkzl_lk.png`, txt: '综合能力考试中的逻辑推理部分主要考查考生对各种信息的理解、分析、判断和综合，以及相应的推理、论证、比较、评价等逻辑思维能力，不考查逻辑学的专业知识', content: ['考408院校及专业汇总', '2021考研考情分析脑图', '考研推免知识汇总篇', '各地推荐免试高校研招办联系方式', '考研推免院校名单下载：全国367所完整版', '2022考情分析及上岸难度解析'] }
    ],
    kyProcess: [
      { time: '1-3月', doing: '选择方向院校', id: nanoid() },
      { time: '7-8月', doing: '发布考研大纲', id: nanoid() },
      { time: '7-10月', doing: '发布招生简章', id: nanoid() },
      { time: '10-11月', doing: '开通报名入口', id: nanoid() },
      { time: '11月', doing: '现场确认', id: nanoid() },
      { time: '12月', doing: '打印准考证', id: nanoid() },
      { time: '12月下旬', doing: '考研初试', id: nanoid() },
      { time: '次年2-5月', doing: '考研复试', id: nanoid() }
    ],
    chooseCourse: [
      { title: '集训营', id: 0, content: [{ pic: `${pzImg}20201231113628183.jpg`, txt: '全年集训营', path: '/zt/qnyfjxy' }, { pic: `${pzImg}20201231113820644.jpg`, txt: '半年集训营', path: '/zt/bnjxy' }, { pic: `${pzImg}20200211021735691.jpg`, txt: '暑期集训营', path: '/zt/sqjxy' }, { pic: `${pzImg}20210716023017402.jpg`, txt: '秋季集训营', path: '/zt/qjjxy' }] },
      { title: '彩虹卡', id: 1, content: [{ txt: '钻石彩虹卡', pic: `${pzImg}/20201203031146966.png`, path: '/zt/caihong' }, { txt: '钻飞跃彩虹卡', pic: `${pzImg}/20201203031313216.png`, path: '/zt/caihong' }, { txt: '魔力彩虹卡', pic: `${pzImg}/20201203031354156.png`, path: '/zt/caihong' }, { txt: '定向彩虹卡', pic: `${pzImg}20201203031438860.png`, path: '/zt/caihong' }] },
      { title: '一对一辅导', id: 2, content: [{ txt: '专业课一对一', pic: `${pzImg}20201203030420609.png`, path: '/zt/zyk1v1' }, { txt: '公共课一对一', pic: `${pzImg}20210811045042713.png`, path: '/zt/ggke' }] },
      { title: '专业硕士', id: 3, content: [{ txt: 'mpacc', pic: `${pzImg}20211014102508829.jpg`, path: '/zt/mpacc' }, { txt: '金融专硕', pic: `${pzImg}20210908024925941.jpg`, path: '/zt/zhym' }, { txt: '法律硕士', pic: `${pzImg}20210908024824487.jpg`, path: '/zt/zhym' }, { txt: '新闻传播', pic: `${pzImg}20211014102524313.jpg`, path: '/zt/zhym' }, { txt: '艺术硕士', pic: `${pzImg}20210908024538288.jpg`, path: '/zt/zhym' }, { txt: '翻译硕士', pic: `${pzImg}20210908024340426.jpg`, path: '/zt/zhym' }, { txt: '农业硕士', pic: `${pzImg}20210908024150971.jpg`, path: '/zt/zhym' }] }
    ],
    aboutUs: [
      { title: '品牌保障', id: nanoid(), intro: '世纪文都教育科技集团有限公司是一家从事考研培训等大学教育服务的专业教育机构。', icon: 'icon-anquanbaozhang', bgUrl: '' },
      { title: '放心机构', id: nanoid(), intro: '文都教育先后荣获了央视网、中国网、新华网、中国保护消费者基金会等机构授予荣誉。', icon: 'icon-jigouguanli', bgUrl: '' },
      { title: '教研匠心研发', id: nanoid(), intro: '文都教育打造以文都教育大数据、文都智能学习系统、文都教育新生态为核心的文都智慧教育。', icon: 'icon-shuyi_shangke-peixun', bgUrl: '' },
      { title: '配套服务', id: nanoid(), intro: '年策划发型图书两百多个品种,两亿多码洋、一千多个教学网点、三十多家直营机构。', icon: 'icon-fuwu', bgUrl: '' }
    ],
    branch: [
      { title: '集训营校区', id: nanoid(), address: '南昌市青山湖区小企业路文都教育集训基地', tel: '400-0791-691' },
      { title: '昌北校区', id: nanoid(), address: '江西省南昌市青山湖区菊圃路118号', tel: '400-0791-691' },
      { title: '前湖校区', id: nanoid(), address: '南昌市南昌大学前湖校区商业街3022-3号商铺', tel: '400-0791-691' },
      { title: '九江校区', id: nanoid(), address: '九江市濂溪区前进东路江西庐山技工学校办公楼一楼111室', tel: '400-0791-691' }
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
