<template>
  <header class="header">
    <!-- 头部组件 -->
    <head-top></head-top>
    <!-- 导航栏区域 -->
    <!-- <div class="nav" :class="{fixed: isFixed === true}"> -->
      <div class="nav">
      <div class="nav-inside maxWidth f-cs">
        <!-- 显示菜单 -->
        <!-- <div class="hotCourse" v-if="isFixed === false" v-show="$route.path === '/'">
          <h4>热门课程</h4>
          <ul class="hotList" :class="{hide: $route.path !== '/home'}" :style="isShow? 'display:block': ''">
            <li v-for="item in hotCourse" :key="item.id">
              <a :href="item.path" target="_blank">{{item.title}}</a>
            </li>
          </ul>
        </div> -->
        <!-- <div class="logo" v-show="ifFixed"> <img src="http://jx.wendu.com/images/logo.png" alt=""></div> -->
        <!-- 折叠菜单 -->
        <!-- <div class="foldList" v-show="$route.path !== '/home'" v-if="isFixed === false">
          <h4><span class="iconfont icon-caidan"></span>热门课程</h4>
          <ul class="hotList">
            <li v-for="item in hotCourse" :key="item.id">
              <a href="">{{item.title}}</a>
            </li>
          </ul>
        </div> -->
        <!-- 导航栏 -->
        <ul class="nav-list f-cs">
          <li v-for="nav in navBar" :key="nav.id" :class="{active: nav.path === $route.path}" @click="distanceTo(nav.distance)" >
            <a :href="nav.path" target="_blank">
              <p>{{nav.title}}</p>
              <div class="drop-down">
                <router-link to="" v-for="item in nav.menu" :key="item.id">{{item.title}}</router-link>
              </div>
            </a>
          </li>
        </ul>
        <!-- 倒计时组件 -->
        <!-- <count-down v-if="!isFixed" v-show="$route.path==='/'"></count-down> -->
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
// import CountDown from './CountDown.vue'
import HeadTop from './HeadTop.vue'
export default {
  name: 'NavBar',
  components: {
    // CountDown,
    HeadTop
  },
  data () {
    return {
      // isFixed: false, // 固定导航栏
      isShow: false
    }
  },
  mounted () {
    // window.addEventListener('scroll', this.watchScroll)
  },
  methods: {
    /* distanceTo (distance) {
      window.scroll(0, distance)
    } */
    // navTo (path) {
    //   this.$router.push(path)
    // },
    // 窗口滚动 处理吸顶
    // watchScroll () {
    //   const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   if (scrollTop > 140) {
    //     this.isFixed = true
    //   } else {
    //     this.isFixed = false
    //   }
    // }
  },
  computed: {
    ...mapState(['navBar', 'hotCourse'])
  }
}
</script>

<style lang="less" scoped>
.header{
  // position: fixed;
  // top: 0;
  // left: 0;
  // z-index: 5;
  width: 100%;
  // height:80px;
  background: #fff;
  // padding: 20px 0;
  box-shadow: 0 2px 8px rgba(0,0,0,0.04);
  .hotCourse{
    width:200px;
    height:inherit;
    background:#007ff4;
    line-height:40px;
    text-align:center;
    color:#fff;
    position:relative;
    z-index:9;
    h4{
      font-size:18px;
    }
    .hide{
      display:none;
    }
    .hotList{
      background:rgba(255, 255, 255, 0.89);
      height:380px;
      display:flex;
      align-items:center;
      flex-direction: column;
      justify-content: space-evenly;
      li,.hotList li{
        width:100%;
        color:#4d4d4d;
        font-size:14px;
        // line-height:43px;
        cursor:pointer;
        position:relative;
        transition:all .3s linear;
        &:hover{
          background:#fff;
        }
        &::before{
          position:absolute;
          width:3px;
          height:0;
          content:'';
          left:0;
          top:0;
          // transform: translateY;
          background:#007ff4;
          transition:all .3s linear;
        }
        &:hover::before{
          height:100%;
        }
      }
    }
  }
  .nav{
    width:100%;
    background:#fff;
  }
  .nav-inside{
    height:40px;
    position:relative;
  }
  .nav-list{
    // flex:1;
    height: inherit;
    width:100%;
    li{
      width:11.111111111111111111111111%;
      position:relative;
      cursor:pointer;
      height:inherit;
      line-height:32px;
      font-size:16px;
      text-align:center;
      transition:all .3s linear;
      a{
        p{
          line-height:40px;
          font-weight:bold;
        }
      }
    }
    /* li::after{
      width:0;
      position:absolute;
      content:'';
      bottom:0;
      border-radius:5px;
      left:50%;
      transform: translateX(-50%);
      height:3px;
      // background:#007ff4;
      background-image:linear-gradient(90deg,#007ff4,#7a32ff);
      transition:all .3s linear;
    }
    li:hover::after{
      width:80%!important;
    } */
    li:hover,.drop-down a:hover{
      color:#007ff4;
    }
    li:first-child:hover > .drop-down,
    li:last-child:hover> .drop-down,
    li:nth-child(8):hover> .drop-down{
      display:none!important;
    }
    li:hover{
      background:#007ff4;
      p{
        color:#fff;
      }
    }
    // li:hover >.drop-down{
    //   display:block!important;
    // }
    .active{
      background:#007ff4;
      color:#fff!important;
      a{
        p{
          color:#fff;
        }
      }
      &::after{
        width:80%!important;
      }
    }
  }
}
.drop-down{
  display:none;
  position:absolute;
  z-index:99999999999;
  width:120px;
  box-shadow: 0px 0px 8px rgba(0,0,0,.05);
  padding:10px 0;
  border-radius:3px;
  border:solid 1px #ebebeb;
  background:#fff;
  left:50%;
  top:50px;
  transform:translateX(-50%);
  transition:all .3s linear;
  &::before{
    position:absolute;
    content:'';
    width:100%;
    height:30px;
    top:-29px;
    left:0;
    background:url(../../static/img/sanjiao.png)no-repeat center bottom;
  }
  a{
    width:100%;
    font-size:14px;
    line-height:36px;
    text-align:center;
  }
}
.fixed{
  position:fixed;
  z-index: 11;
  top:0;
  height:40px;
  display:flex;
  align-items:center;
  width:1200px;
  background:#fff;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}
.foldList{
  position:relative;
  // margin-left:50px;
  // top:88px;
  &:hover > ul{
    height:378px;
    transition:height .3s linear
  }
  h4{
    height:40px;
    line-height:30px;
    font-size:20px;
    color:#000;
    .iconfont{
      font-size:20px;
      margin-right:10px;
      color:#007ff4;
    }
  }
  ul{
    position:absolute;
    top:50px;
    left:0;
    height:0;
    overflow:hidden;
    width:200px;
    background:rgba(255, 255, 255, 0.89);
    box-shadow:0 0 10px 0 rgba(0,0,0,0.04);
    transition:all .3s linear;
    li{
      color:#4d4d4d;
      font-size:14px;
      line-height:42px;
      cursor:pointer;
      text-align:center;
      position:relative;
      transition:all .3s linear;
      &:hover{
        background:#fff;
      }
      &::before{
        position:absolute;
        width:3px;
        height:0;
        content:'';
        left:0;
        top:10%;
        // transform: translateY;
        background:#007ff4;
        transition:all .3s linear;
      }
      &:hover::before{
        height:80%;
      }
    }
  }
}
</style>
