import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './mock/index.js'
import moment from 'moment'
import axios from 'axios'
import '../config/index'
Vue.prototype.$moment = moment
Vue.prototype.$http = axios
moment.locales('zh-cn')

Vue.config.productionTip = false

Vue.filter('dateFormat', (value, formatStr = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(value).format(formatStr)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 路由跳转时页面归顶
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
