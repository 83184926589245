<template>
  <div class="bkGuide">
    <!-- 板块标题组件 -->
    <part-title>
      <h3 class="part-title f-cc" slot="titleIcon">
        <span class="iconfont icon-7"></span>
        报考指南
      </h3>
    </part-title>
    <div class="guideContent">
      <ul>
        <li v-for="(item,index) in guide" :key="index" class="link">{{item}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Title from './Title.vue'
import { mapState } from 'vuex'
export default {
  components: {
    PartTitle: Title
  },
  computed: {
    ...mapState(['guide'])
  }
}
</script>

<style lang="less" scoped>
  .bkGuide{
    width:calc(1200px - 840px);
    height:340px;
    background:#fff;
  }
  .guideContent{
    padding:20px;
    ul{
      display:flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      li{
        width: 98px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        font-size:14px;
        text-align: center;
        background: #f7f7f8;
        margin-bottom: 20px;
        transition: all .2s linear;
        cursor:pointer;
        &:nth-child(13),
        &:nth-child(14),
        &:nth-child(15){
          margin-bottom:0;
        }
        &:hover{
          background:#007ff4;
          color:#fff!important;
        }
      }
    }
  }
</style>
