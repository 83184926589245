import Mock from 'mockjs'
// 生成指定数据
// const data = Mock.mock({
//   'string|4': '哎哟,'
// })
// console.log(data)

// 随机数据：@xxx(长度,范围)

// 生成随机数据
// const data = Mock.mock({
// string: '@cword(3)', // 字符串文本
// title: '@ctitle(6)', // 标题
// sentence: '@csentence(20,50)', // 句子
// content: '@cparagraph(50)', // 段落
// 'number|1-100': 10, // 数字
// id: '@increment(1)' // 增量id
// name: '@cname()', // 姓名
// idCard: '@id()', // 身份证
// address: '@city(true)' // 地址
// img_url: '@image("300x250","#4d4d4d","#fff","jpg","奥里给")', // 图片
// time: '@date(yyyy-mm-dd hh:mm:ss)' // 时间
// 'list|5-20': [
//   { name: '@cname()', address: '@city(true)', id: '@increment(1)' }
// ]
// })
// console.log(data)

// mock拦截请求

// 定义get请求API 对象形式
// Mock.mock('/api/news', 'get', {
//   status: 200,
//   msg: '获取数据成功get'
// })

// 定义post 对象形式
Mock.mock('/api/user', 'post', {
  status: 200,
  msg: '获取数据成功post'
})

// 函数形式
Mock.mock('/api/news', 'get', () => {
  return {
    status: 200,
    msg: '获取数据成功get'
  }
})
