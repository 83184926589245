<template>
  <div class="chooseCourse mt-20">
    <part-title>
      <h3 class="part-title f-cc" slot="titleIcon">
        <span class="iconfont icon-caidan"></span>
        选课中心
      </h3>
    </part-title>
    <div class="courseContent">
      <ul class="courseTab">
        <li
          v-for="itemTab in chooseCourse"
          :key="itemTab.id" :class="{active:itemTab.id === curId}"
          @mouseover="toggleTab(itemTab.id)"
        >{{itemTab.title}}</li>
      </ul>
      <div class="tab-con">
        <div v-show="index === curId" v-for="item,index in allCourse[0]" :key="index">
          <ul>
            <li v-for="child,index in item.content" :key="index">
              <a :href="child.path" target="_blank">
                <img :src="child.pic" alt="">
                <p>{{child.txt}}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Title from './Title.vue'
export default {
  components: {
    PartTitle: Title
  },
  data () {
    return {
      curId: 0, // 当前项下标
      allCourse: []
    }
  },
  mounted () {
    this.allCourse.push(this.chooseCourse)
  },
  methods: {
    toggleTab (index) {
      this.curId = index
      // console.log(index)
    },
    // 专题页跳转
    pageSkip (path) {
      this.$router.push(path)
    }
  },
  computed: {
    ...mapState(['chooseCourse'])
  }
}
</script>

<style lang="less" scoped>
.chooseCourse{
  background:#fff;
}
.courseContent{
  padding:20px;
}
.courseTab{
  display:flex;
  align-items:center;
  flex-wrap: wrap;
  li{
    padding:10px 30px;
    background:#f7f7f8;
    text-align:center;
    border-radius:3px;
    margin-right:30px;
    transition:all .3s linear;
  }
  .active,li:hover{
    background:url(http://jx.wendu.com/images/course_tab_bg.jpg)no-repeat center!important;
    background-size:cover!important;
    // font-weight:bold;
    color:#fff!important;
  }
}
.tab-con{
  margin-top:20px;
  ul{
    display:flex;
    align-items: center;
    flex-wrap: wrap;
  }
  li{
    width:276px;
    margin-right:18px!important;
    overflow:hidden;
    border: solid 1px #f7f7f7;
    transition:all .3s ease-in-out;
    &:nth-child(4),&:nth-child(8){
      margin-right:0!important;
    }
    &:nth-child(5),&:nth-child(6),&:nth-child(7),&:nth-child(8){
      margin-top:20px;
    }
    img{
      width: 100%;
    }
    &:hover{
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08)
    }
    p{
      padding:15px 20px;
    }
  }
}

</style>
