<template>
  <footer class="footer">
    <div class="branch f-cc">
      <div class="branch_inside f-cs maxWidth">
        <ul class="f-cs">
          <li v-for="item in branch" :key="item.id">
            <h4>{{item.title}}</h4>
            <div class="address_popup txt-x">
              {{item.address}}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer_inside">
      <div class="footer_inside_left fl">
        <ul>
          <li v-for="item in navBar" :key="item.id">
            <a :href="item.path" target="_blank">{{item.title}}</a>
          </li>
        </ul>
        <p>
          总部：北京市海淀区中关村南大街17号韦伯时代中心C座北配楼<br />江西分部：江西省南昌市青山湖区蛟桥镇麦园路住宅小区西南文都教育<br />
          <span style="margin-right: 40px">全国咨询热线：4000791691</span>
          工作时间：9:00-21:00<br />
          京ICP备05001816号
        </p>
      </div>
      <div class="footer_inside_right">
        <ul>
          <li>
            <img
              src="http://jx.wendu.com/images/weibo.png"
              alt=""
              width="100%"
            />
            <p>扫码了解更多资讯</p>
          </li>
          <li>
            <img
              src="http://jx.wendu.com/images/wechat.jpg"
              alt=""
              width="100%"
            />
            <p>扫码了解更多资讯</p>
          </li>
        </ul>
      </div>
    </div>
    <p
      style="text-align: center; border-top: solid 1px #666; line-height: 50px"
    >
      Copyright ©2001-2021 WENDU Corporation All Rights Reserved.
      世纪文都教育科技集团股份有限公司 版权所有
    </p>
    <div class="clear"></div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
export default {
  methods: {
    /* distanceTo (distance) {
      window.scroll(0, distance)
    } */
  },
  computed: {
    ...mapState(['navBar', 'branch'])
  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  background: #333;
  color: #fff;
  overflow: hidden;
  font-size: 14px;
  margin-top: 30px;
}
.footer_inside {
  width: 1200px;
  margin: 18px auto 30px;
  display: flex;
  justify-content: space-between;
}
.footer_inside_left ul {
  overflow: hidden;
  margin-bottom: 12px;
}
.footer_inside_left ul li {
  float: left;
  box-sizing: border-box;
  padding-right: 12px;
  margin-right: 12px;
  font-size: 15px;
  border-right: solid 1px #595959;
}
.footer_inside_left ul li a{
  color:#fff;
  font-size:15px;
}
.footer_inside_left ul li:last-child {
  border: none !important;
}
.footer_inside_left p {
  line-height: 30px;
  font-size:13px;
}
.footer_inside_right{
  display:none;
}
.footer_inside_right ul li {
  float: left;
  width: 120px;
  height: 150px;
  overflow: hidden;
}
.footer_inside_right ul li:nth-child(1) {
  margin-right: 50px;
}
.footer_inside_right ul li p {
  line-height: 40px;
  text-align: center;
}
// 校区地址
.branch{
  width:100%;
  color:#bdbdbd;
}
.branch .branch_inside{
  box-sizing: border-box;
  margin-top:15px;
}
.branch .branch_inside ul{
  flex: 1;
  align-items: initial!important;
}
.branch_inside ul li {
  width:23%;
  position:relative;
  font-size:15px;
  color:#fff;
  transition: all .3s linear;
}
.branch_inside ul li h4{
  line-height:36px;
  border-bottom:solid 1px #595959;
}
.address_popup{
  margin-top:5px;
  font-size:12px;
  line-height:20px;
  color:#bdbdbd;
  transition: all .3s linear;
}
.address_popup:hover{
  color:#fff;
}
</style>
