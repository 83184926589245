<template>
  <div v-pre class="head-top maxWidth f-cs">
    <div class="logo f-cs">
      <img src="http://yx.wendu.com/images/logo.png" alt="" />
      <h5>江西考研</h5>
    </div>
    <div class="search f-cs">
      <div class="ipt f-cs">
        <img src="https://yx.wendu.com/images/search.jpg" alt="" />
        <input type="text" maxlength="15" />
      </div>
      <button>搜索</button>
    </div>
    <div class="tel f-cs">
      <span class="iconfont icon-dianhua"></span>
      <h4>
        <p>咨询热线9:00-21:00</p>
        400-0791-691
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  /* methods: {
    tips () {
      alert('搜索失败！')
    }
  } */
}
</script>

<style lang="less" scoped>
.head-top{
  background:#fff;
  padding:25px 0;
  .logo{
    // width:300px;
    margin-right:15px;
    img{
      width:160px;
    }
    h5{
      font-size:17px;
      font-weight:bold;
      position:relative;
      margin-left:15px;
      padding:0 0 3px 15px;
      color:#007ff4;
      &::before{
        position:absolute;
        content:'';
        left:0;
        width:1px;
        top:15%;
        height:70%;
        background:#007ff4;
      }
    }
  }
  .search{
    flex:1;
    margin:0 100px 0 140px;
    height:36px;
    .ipt{
      flex:1;
      height:inherit;
      border:1px solid #ddd;
    }
    overflow:hidden;
    img{
      width:20px;
      margin-left:12px;
    }
    input{
      flex:1;
      margin:0 0 0 15px;
      line-height:28px;
      border:none;
      color:#777;
    }
    button{
      width:80px;
      cursor:pointer;
      border:none;
      background:#007ff4;
      color:#fff;
      font-size:16px;
      height:inherit;
    }
  }
  .tel{
    // width:200px;
    // margin-right:50px;
    .iconfont{
      font-size: 35px;
      margin-right: 8px;
      color: #007ff4;
    }
    h4{
      font-size: 18px;
      font-weight: 700;
      color: #007ff4;
      p{
        font-size: 13px;
        letter-spacing: 0.5px;
        color: #999;
        font-weight: 400;
        line-height: 13px;
      }
    }
  }
}
</style>
