<template>
  <div class="course">
    <ul class="f-cs">
      <li v-for="item in course[0].content" :key="item.id">
        <a :href="item.path" target="_blank">
          <img :src="item.pic" alt="">
          <p class="txt">{{item.title}}</p>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  mounted () {
    // console.log(this.course)
  },
  computed: {
    ...mapState(['course'])
  }
}
</script>

<style lang="less" scoped>
.course{
  padding:20px;
  li{
    overflow:hidden;
    border: solid 1px #f7f7f7;
    transition:all .3s ease-in-out;
    &:hover{
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08)
    }
    p{
      padding:15px 20px;
    }
  }
}
</style>
