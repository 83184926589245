module.exports = {
  dev: {
    proxyTable: {
      '/api': {
        target: 'http://group-mgr.easyliao.com/', // 目标接口域名
        changeOrigin: true, // 是否跨域
        pathRewrite: {
          '^/api': '/api' // 重写接口
        }
      }
    }
  }
}
