<template>
  <div class="newsListCard f-cs">
    <div class="card" v-for="newsGroup in news" :key="newsGroup.id">
      <h3 class="f-cc">
        <span class="iconfont" :class="newsGroup.icon"></span>
        {{newsGroup.title}}
      </h3>
      <ul>
        <li v-for="item in newsGroup.list" :key="item.id">
          <a href="javascript:void(0)" class="txt link">{{item.txt}}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      sliceNews: []
    }
  },
  mounted () {
    // const n = this.news
    // this.newsList()
  },
  computed: {
    ...mapState(['news'])
  }
}
</script>

<style lang="less" scoped>
.newsListCard{
  width:100%;
  background:#fff;
  .card{
    width:calc(100% / 6);
    padding:5px 25px 15px;
    overflow:hidden;
    position:relative;
    transition:all .3s linear;
    &::after{
      position:absolute;
      content:'';
      right:0;
      top:0;
      height:100%;
      width:1px;
      background:#f7f7f7;
    }
    &:last-child::after{
      display:none;
    }
    &:hover{
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.07);
    }
    &:hover::after{
      display:none;
    }
    h3{
      font-size:16px;
      font-weight:normal;
      color:#000;
      height:50px;
      line-height:40px;
      border-bottom:solid 1px #eee;
      .iconfont{
        width:24px;
        height:24px;
        line-height:24px;
        border-radius:12px;
        margin-right:8px;
        // background-image: linear-gradient(90deg, #007ff4, #74bcff);
        // background-clip: text;
        // -webkit-background-clip: text;
        // color: transparent;
        background-image:linear-gradient(90deg,#007ff4,#74bcff);
        font-size:13px;
        color:#fff;
        text-align:center;
      }
    }
    ul{
      padding:10px 0 0;
      li{
        font-size:14px;
        line-height:34px;
        a{
          color:#53586c;
        }
      }
    }
  }
}
</style>
