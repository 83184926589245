<template>
  <div id="app">
    <nav-bar></nav-bar>
    <banner></banner>
    <div class="main maxWidth">
      <!------------------ 文章列表组件 -------------------------->
      <home-news-card></home-news-card>
      <!------------------ 考研资讯、报考指南板块 ---------------->
      <div class="part-1 f-cs mt-20">
        <!-- 考研资讯组件 -->
        <ky-msg></ky-msg>
        <!-- 报考指南组件 -->
        <bk-guide></bk-guide>
      </div>
      <!------------------- 热门课程组件 ------------------------->
      <div class="hotCourse mt-20">
        <!-- 板块标题组件 -->
        <part-title>
          <h3 class="part-title f-cc" slot="titleIcon">
            <span class="iconfont icon-rementuijian"></span>
            热门课程
          </h3>
        </part-title>
        <!-- 课程组件 -->
        <course></course>
      </div>
      <!---------------- 备考资料、开课通知板块 ---------------->
      <div class="part-2 f-cs mt-20">
        <!-- 备考资料组件 -->
        <materials></materials>
        <!-- 考研流程 -->
        <ky-process></ky-process>
      </div>
      <!-- 考研课程组件 -->
      <choose-course></choose-course>
      <!-- 企业介绍组件 -->
      <about-us></about-us>
    </div>
    <!-- 页脚组件 -->
    <web-footer></web-footer>
  </div>
</template>
<script>
// import axios from 'axios'
import NavBar from '@/components/NavBar'
import Banner from '@/components/Banner'
import HomeNewsCard from '@/components/HomeNewsCard.vue'
import KyMsg from '@/components/KyMsg.vue'
import BkGuide from '@/components/bkGuide.vue'
import Title from '@/components/Title.vue'
import Course from '@/components/Course.vue'
import Materials from '@/components/Materials.vue'
import KyProcess from '@/components/KyProcess.vue'
import ChooseCourse from '@/components/ChooseCourse.vue'
import AboutUs from '@/components/AboutUs.vue'
import WebFooter from '@/components/WebFooter.vue'
export default {
  components: {
    NavBar,
    Banner,
    HomeNewsCard,
    KyMsg,
    BkGuide,
    PartTitle: Title,
    Course,
    Materials,
    KyProcess,
    ChooseCourse,
    AboutUs,
    WebFooter
  },
  created () {
    // axios.get('/api/news').then(res => {
    //   console.log(res)
    // })
    // axios.post('/api/user').then(res => {
    //   console.log(res)
    // })
  }
}
</script>

<style lang="less">
// 公共css
@import url('../static/css/global.css');
.part-1{
  height:340px;
  overflow:hidden;
}
.hotCourse{
  background:#fff;
}
.part-2{
  align-items: inherit;
}
</style>
