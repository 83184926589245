<template>
  <div class="about mt-20">
    <part-title>
      <h3 class="part-title f-cc" slot="titleIcon">
        <span class="iconfont icon-sousuochazhaofangdajing"></span>
        专注考研教育
      </h3>
    </part-title>
    <div class="content">
      <ul class="f-cs">
        <li v-for="item in aboutUs" :key="item.id">
          <div class="brand">
            <div class="brand_icon f-ccc">
              <span class="iconfont" :class="item.icon"></span>
              <h2>{{item.title}}</h2>
            </div>
            <div class="hover_show">
                <p>{{item.intro}}</p>
                <a href="javascript:void(0)" class="link">预约试听</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Title from './Title.vue'
export default {
  components: {
    PartTitle: Title
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['aboutUs'])
  }
}
</script>

<style lang="less" scoped>
.about{
  background:#fff;
}
.content{
  padding:20px;
  color:#fff;
  li{
    width:280px;
    background:rgba(0, 0, 0,.7);
  }
  .brand{
    width:100%;
    height:373px;
    overflow:hidden;
    position:relative;
    background:url(http://jx.wendu.com/images/brand_1.png)no-repeat center;
    background-size:280px;
  }
  li:nth-child(2) .brand{
      background:url(https://jx.wendu.com/images/brand_2.png)no-repeat center!important;
  }
  li:nth-child(3) .brand{
      background:url(https://jx.wendu.com/images/brand_3.png)no-repeat center!important;
  }
  li:nth-child(4) .brand{
      background:url(https://jx.wendu.com/images/brand_4.png)no-repeat center!important;
  }
  .brand_icon{
    padding-top:130px;
    transition:all .7s linear;
    h2{
      font-size:26px;
      font-weight:normal;
      margin-top:15px;
      text-align:center;
    }
    .iconfont{
      font-size:50px;
      font-weight:200;
    }
  }
  .hover_show {
    position:absolute;
    width:162px;
    top:50%;
    left:50%;
    transform:translate(-50%,100%);
    transition:all .3s linear;
    p{
      line-height:34px;
      font-size:16px;
    }
    a{
      width:90%;
      margin:20px auto 0;
      height:36px;
      line-height:36px;
      border-radius:20px;
      border:solid 1px #fff;
      color:#fff;
      text-align:center;
      transition:all .3s linear;
      font-size:16px;
    }
  }
  li:hover>.brand>.hover_show{
    transform:translate(-50%,-50%);
  }
  li:hover>.brand>.brand_icon{
    transform: translateY(-170%);
  }
  li>.brand>.hover_show>a:hover{
    background:#fff;
    color:#007ff4;
  }
}
</style>
