<template>
  <div class="materials">
    <part-title>
      <h3 class="part-title f-cc" slot="titleIcon">
        <span class="iconfont icon-word"></span>
        备考资料
      </h3>
    </part-title>
    <ul class="tab-bar f-cs">
      <li v-for="parent in materials" :key="parent.id" :class="{active: parent.id === curId}" @mouseover="toggleTab(parent.id)">{{parent.title}}</li>
    </ul>
    <div class="tab-con">
      <div v-show="index === curId" v-for="item,index in allData[0]" :key="index">
        <div class="left fl">
          <img :src="item.imgUrl" width="100%" alt="">
          <div class="abstract mt-20 f-cs">
            <img src="https://yx.wendu.com/images/Abstract.png" alt="">
            <p class="txt-x">{{item.txt}}</p>
          </div>
        </div>
        <div class="right fr">
          <ul>
            <li v-for="child,index in item.content" :key="index">
              <a href="javascript:void(0)" class="f-cs link">
                <span class="iconfont icon-word"></span>
                <p class="txt">{{child}}</p>
                <p class="p2">
                  <span class="iconfont icon-xiazai"></span>
                  下载
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Title from './Title.vue'
export default {
  components: {
    PartTitle: Title
  },
  data () {
    return {
      curId: 0, // 当前项下标
      allData: []
    }
  },
  mounted () {
    // console.log(this.materials.length)
    this.allData.push(this.materials)
    // console.log(this.allData)
  },
  methods: {
    toggleTab (index) {
      this.curId = index
    }
  },
  computed: {
    ...mapState(['materials'])
  }
}
</script>

<style lang="less" scoped>
.materials{
  width:900px;
  background:#fff;
  position:relative;
}
.tab-bar{
  position:absolute;
  top:0;
  right:0;
  width:600px;
  height:55px;
  // box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.03);
  background:#fff;
  li{
    width:calc(100% / 5);
    text-align: center;
    cursor:pointer;
  }
}
.tab-con{
  padding:20px;
  width:100%;
  align-items: initial;
  div{
    // display:none;
    .right{
      width:56%;
    }
    .left{
      width:41%;
      margin-right:3%;
      .abstract{
        width: 100%;
        height: 80px;
        background: #f7f7f7;
        overflow:hidden;
        img{
          height:100%;
        }
        p{
          width: calc(374px - 90px);
          padding: 0 20px;
          line-height: 28px;
          font-size: 16px;
          color: #444;
        }
      }
    }
  }
}
.active{
  border-top: solid 4px #47af39;
  line-height: 50px!important;
  // height:56px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.07);
  border-right: none!important;
}
.right{
  li{
    line-height:30px;
    font-size:14px;
    margin-bottom:7px;
    &:last-child{
      margin-bottom:0;
    }
    &:hover .icon-word{
      color:#007ff4;
    }
    a{
      .iconfont{
        color:#d0d0d0;

      }
      color:#444;
      .txt{
        flex:1;
        margin:0 50px 0 20px;
      }
      .icon-xiazai{
        font-size:12px;
      }
      .p2{
        color:#999;
        &:hover,&:hover span{
          color:#007ff4;
        }
        span{
          font-size:20px;
          display:inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
