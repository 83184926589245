<template>
  <div class="title f-cs">
    <slot name="titleIcon"></slot>
    <p class="f-cs link">全部<span class="iconfont icon-gengduo"></span></p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.title{
  width:100%;
  height:56px;
  border-bottom:solid 1px #f7f7f7;
  padding:0 20px;
  background:#fff;
  &:hover >.part-title span{
    animation:shake .3s 2 alternate ease-in-out;
  }
  .part-title{
    flex:1;
    font-size:20px;
    span{
      margin-right:6px;
      font-size:26px;
      background-image: linear-gradient(90deg, #007ff4, #74bcff);
      background-clip:text;
      -webkit-background-clip:text;
      color: transparent;
    }
  }
  p{
    line-height:56px;
    width:48px;
    font-size:14px;
    cursor:pointer;
    span{
      transition:all .3s linear;
    }
    &:hover{
      color:#007ff4;
    }
    &:hover >span{
      transform: rotate(90deg);
    }
  }
}
</style>
