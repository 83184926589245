<template>
  <div class="kyProcess">
    <part-title>
      <h3 class="part-title f-cc" slot="titleIcon">
        <span class="iconfont icon-tongzhi2"></span>
        考研流程
      </h3>
    </part-title>
    <ul class="process">
      <li v-for="item in kyProcess" :key="item.id" class="f-cs">
        <p>{{item.time}}</p>
        <p>{{item.doing}}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Title from './Title.vue'
export default {
  components: {
    PartTitle: Title
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState(['kyProcess'])
  }
}
</script>

<style lang="less" scoped>
.kyProcess{
  position:relative;
  width:calc(100% - 930px);
  height:377px;
  margin-right:10px;
  // background:#fff;
  z-index: 1;
  // box-shadow:-4px 0 15px 0 rgba(147,200,255,0.2);
  &::after{
    position:absolute;
    z-index: -1;
    content:'';
    top:20px;
    right:-10px;
    width:260px;
    height:378px;
    background:#e7eaf0;
    // background:#a2c29e;
    border-radius:0 0 8px 0;
  }
}
.process{
  height:calc(397px - 66px);
  position:relative;
  width:100%;
  padding:20px 20px 20px 30px;
  background:#fff;
  border-radius:0 0 5px 0;
  &::after{
    position:absolute;
    content:'';
    left:50%;
    top:20px;
    width:2px;
    height:290px;
    background-image:linear-gradient(180deg,#ffa800,#ff4b00);
    transform: translateX(-50%);
  }
  li{
    position:relative;
    width:100%;
    line-height:22px;
    margin-bottom:16px;
    font-size:14px;
    transition:all .3s linear;
    &:nth-child(even) p:first-child {
      background:#44ae36;
      color:#fff!important;
      opacity: .8;
    }
    &:nth-child(even) p:first-child::after {
      border-top: 4px solid transparent;
      border-left: 7px solid #44ae36;
      border-bottom: 4px solid transparent;
    }
    &:last-child{
      margin-bottom:0;
    }
    &:hover{
      animation:zoom .7s 2 alternate ease-in-out;
    }
    &::after/* ,&::before */{
      position:absolute;
      content:'';
      z-index: 3;
      width:8px;
      height:8px;
      border-radius:4px;
      top:50%;
      left:46%;
      transform: translateY(-50%);
      background:#ffa300;
    }
    &::before{
      width:10px;
      height:10px;
      border-radius:6px!important;
      background:none;
      border:solid 1px #ffa300;
    }
    p{
      width:100px;
    }
    p:first-child{
      position:relative;
      width:75px;
      text-align: center;
      color:#fff;
      background:#ffc000;
      border-radius:11px 4px 4px 11px;
      &::after{
        position:absolute;
        content:'';
        top:50%;
        right:-7px;
        transform:translateY(-50%);
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-left: 7px solid #ffc000;
        border-bottom: 4px solid transparent;
      }
    }
    p:last-child{
      text-align:left;
    }
  }
}
</style>
