<template>
  <div class="banner">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide link" v-for="item in banner" :key="item.id">
          <img :src="item.imgUrl" width="100%" alt="">
        </div>
      </div>
      <!-- 分页器 -->
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
// 导入swiper
export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
    // 创建swiper实例，实现轮播
    const sp = new Swiper('.swiper-container', {
      loop: true, // 循环轮播
      // autoplay: true,
      pagination: {
        el: '.swiper-pagination'
      }
    })
    console.log(sp)
  },
  methods: {

  },
  computed: {
    ...mapState(['banner'])
  }
}
</script>

<style lang="less" scoped>
.banner{
  width:100%;
  cursor: pointer;
  // padding:20px 0;
  .slide{
    width:100%;
    // height:380px;
    overflow:hidden;
    // background:url(https://yx.wendu.com/images/202109161421209048.png)no-repeat center;
    background-size:cover
  }
}
</style>
